import Cookies from 'js-cookie';

import { getEnvVar } from '#shared/envs';
import { removeSwitchUser, setActAsUser, setSwitchUser } from '#shared/services/switchUser';

const JWTPath = getEnvVar('VITE_JWT_PATH');

export const getToken = (): string | undefined => {
  if (JWTPath && sessionStorage.getItem(JWTPath)) {
    return sessionStorage.getItem(JWTPath) ?? undefined;
  }
  return JWTPath ? Cookies.get(JWTPath) : undefined;
};

export const persistToken = (token: string): void => {
  if (JWTPath !== undefined) {
    Cookies.set(JWTPath, token, {
      domain: getEnvVar('VITE_COOKIE_DOMAIN'),
    });
  }
  setActAsUser(undefined);
};

export const removeToken = (): void => {
  if (JWTPath !== undefined) {
    if (sessionStorage.getItem(JWTPath)) {
      return sessionStorage.removeItem(JWTPath);
    }
    if (Cookies.get(JWTPath)) {
      Cookies.remove(JWTPath, {
        domain: getEnvVar('VITE_COOKIE_DOMAIN'),
      });
    }
  }
  removeSwitchUser();
};

export const setSessionToken = (queryParams: string): void => {
  const urlSearchParams = new URLSearchParams(queryParams);

  if (JWTPath && urlSearchParams.has('token') && !sessionStorage.getItem(JWTPath)) {
    sessionStorage.setItem(getEnvVar('VITE_JWT_PATH') as string, urlSearchParams.get('token') as string);
  }

  const appAsCustomerPath = getEnvVar('VITE_AS_CUSTOMER_PATH');
  if (urlSearchParams.has('appAsCustomer') && appAsCustomerPath && !sessionStorage.getItem(appAsCustomerPath)) {
    setActAsUser(urlSearchParams.get('appAsCustomer') as string);
  }

  const switchUserTokenPath = getEnvVar('VITE_SWITCH_USER_PATH');
  if (urlSearchParams.has('switchUserToken') && switchUserTokenPath && !sessionStorage.getItem(switchUserTokenPath)) {
    setSwitchUser(urlSearchParams.get('switchUserToken') as string);
  }
};

import axios, {
  AxiosHeaders,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosRequestTransformer,
  AxiosResponse,
  CancelTokenSource,
  Method,
} from 'axios';
import cookies from 'js-cookie';

import { getEnvVar } from '#shared/envs';
import { CJSONLD, CollectionPaginated, Filters, Order, PathParam } from '#shared/typings/api';
import { paths } from '#shared/typings/schema';
import { buildQueryParams, sanitizeUrl } from '#shared/utils/normalizeUrl';

const requests: Record<string, CancelTokenSource> = {};
const cancelToken = axios?.CancelToken;

export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;

type CollectionAPIParams = {
  page?: number | null;
  itemsPerPage?: number;
  search?: string | null;
  orderKey?: string | null;
  orderDirection?: string | null;
  order?: Order;
  filters?: Filters;
};

export const buildCollectionFetchingParams = ({
  page = 1,
  itemsPerPage = 10,
  search = '',
  filters,
  order = {},
  orderKey,
  orderDirection,
}: CollectionAPIParams): string => {
  if (orderKey && orderDirection) {
    order[orderKey] = orderDirection as 'desc' | 'asc'; // FIXME remove when legacy list will be removed
  }

  return buildQueryParams({
    page: page ?? 1,
    itemsPerPage,
    q: search,
    order,
    ...filters,
  });
};

// TODO : remove exclusion after replacing controller by a provdier
export function generateApiPath<Path extends keyof paths | '/v1/offers/{id}/exclusion'>(
  originalPath: Path,
  params: {
    [key in PathParam<Path>]: string | null;
  } = {} as any,
  filters: object = {},
): string {
  const path: string = originalPath;
  const prefix = path.startsWith('/') ? '/' : '';

  const stringify = (p: any): string => (p == null ? '' : typeof p === 'string' ? p : String(p));

  const segments = path
    .split(/\/+/)
    .map((segment, index, array) => {
      const isLastSegment = index === array.length - 1;

      if (isLastSegment && segment === '*') {
        const star = '*' as PathParam<Path>;
        return stringify(params[star]);
      }

      const keyMatch = segment.match(/^\{([\w-]+)(\??)\}$/);
      if (keyMatch) {
        const [, key] = keyMatch;
        const param = params[key as PathParam<Path>];

        if (param === null) {
          throw new Error(`Missing "{${key}}" param`);
        }
        return stringify(param);
      }

      return segment;
    })
    .filter((segment) => !!segment);

  const searchParams = buildQueryParams(filters);
  return prefix + segments.join('/') + ('' !== searchParams ? '?' + searchParams : '');
}

export function externalRequestAPI<T>(): (options: AxiosRequestConfig) => AxiosPromise<T> {
  return async (options: AxiosRequestConfig) => {
    const axiosInstance: AxiosInstance = axios.create();

    return axiosInstance.request<T>({
      ...options,
    });
  };
}

export function requestAPI<T>(): (options: AxiosRequestConfig) => AxiosPromise<T> {
  return async (options: AxiosRequestConfig) => {
    // Pass JWT token only when needed
    options.headers = options.headers || new AxiosHeaders();

    const token = `${options.method}-${options.url}-${JSON.stringify(options.data ?? {})}`;
    try {
      const source = cancelToken?.source();

      if (requests[token]) {
        requests[token]?.cancel();
      }
      requests[token] = source;

      const axiosResponse: AxiosResponse<T> = await instance.request<T>({
        ...options,
        cancelToken: source?.token,
      });
      delete requests[token];

      return axiosResponse;
    } catch (exception) {
      if (axios.isCancel(exception)) {
        return {
          data: null,
          status: 409,
          statusText: 'cancelled',
          headers: new AxiosHeaders(),
          config: options,
        } as AxiosResponse<T>;
      }

      delete requests[token];

      throw exception;
    }
  };
}

export function externalWriteItem<T>(
  method: Method,
  headers?: AxiosRequestConfig['headers'],
): (
  url: string,
  data: {
    arg: any;
  },
) => Promise<T> {
  return async (
    url: string,
    {
      arg,
    }: {
      arg: any;
    },
  ): Promise<T> => {
    const { data }: AxiosResponse<T> = await externalRequestAPI<T>()({
      url,
      method,
      data: arg,
      headers: headers,
    });
    return data;
  };
}

export function writeItem<T>(method: Method): (
  url: string,
  data: {
    arg: any;
  },
) => Promise<T> {
  return async (
    url: string,
    {
      arg,
    }: {
      arg: any;
    },
  ): Promise<T> => {
    const { data: responseData }: AxiosResponse<T> = await requestAPI<T>()({
      url,
      method,
      data: arg,
    });
    return responseData;
  };
}

export async function getItem<T>(url: string): Promise<T> {
  const { data }: AxiosResponse<T> = await requestAPI<T>()({ url });
  return data;
}

export async function getCollection<T>(url: string): Promise<CollectionPaginated<T>> {
  const { data }: AxiosResponse<CJSONLD> = await requestAPI<CJSONLD>()({ url });
  return {
    items: data['hydra:member'],
    totalItems: data['hydra:totalItems'],
  };
}

export const dateNormalizer: AxiosRequestTransformer = function (data, headers) {
  if (data instanceof Date) {
    // do your specific formatting here
    return data.toISOString();
  }
  if (Array.isArray(data)) {
    return data.map((val) => dateNormalizer.apply(this, [val, headers]));
  }
  if ('object' === typeof data && null !== data) {
    return Object.fromEntries(
      Object.entries(data).map(([key, val]) => [key, dateNormalizer.apply(this, [val, headers])]),
    );
  }
  return data;
};

const instance = axios.create({
  withCredentials: true,
  headers: {
    Accept: 'application/ld+json',
    'Content-Type': 'application/json',
  },
  transformRequest: [dateNormalizer].concat(axios.defaults.transformRequest || []),
});

export const boostrapInterceptorRequest = (
  onRequestAuthentication: (requestConfigHeaders: AxiosRequestHeaders) => void,
  removeToken: () => void,
  redirectToLogout: () => void,
): void => {
  instance.interceptors.request.use((requestConfig) => {
    if (cookies.get('XDEBUG_SESSION')) {
      requestConfig.params = { ...requestConfig.params, XDEBUG_SESSION: '1' };
    }
    requestConfig.baseURL = sanitizeUrl(
      `${getEnvVar('VITE_API_ENDPOINT')}${requestConfig.url?.startsWith(getEnvVar('VITE_API_PATH') as string) ? '' : getEnvVar('VITE_API_PATH')}/`,
    );
    requestConfig.headers['Client-Referer'] = window.location.href;
    requestConfig.headers['Client-Release'] = window.APP_VERSION;
    requestConfig.headers.Application = 'stello';

    onRequestAuthentication(requestConfig.headers);

    return requestConfig;
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
      if (error.request) {
        const path = new URL(error.request.responseURL).pathname;
        if (HTTP_UNAUTHORIZED === error?.response?.status && path.indexOf('login') === -1) {
          removeToken();
          redirectToLogout();
        }
      }

      return Promise.reject(error);
    },
  );
};

export default instance;

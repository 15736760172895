import { addMethod, mixed } from 'yup';

const validFileExtensions = ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp', 'pdf'];

const MAX_FILE_SIZE = 5242880;

addMethod(mixed<File>, 'validFileType', function (message) {
  return this.test('validFileType', message, (value) => {
    if (!value) {
      return false;
    }
    const extension = value.name.toLowerCase().split('.').pop();
    return extension ? validFileExtensions.indexOf(extension) > -1 : false;
  });
});

addMethod(mixed<File>, 'validFileSize', function (message) {
  return this.test('validFileSize', message, (value) => {
    return value ? value.size <= MAX_FILE_SIZE : false;
  });
});

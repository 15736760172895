import clsx from 'clsx';

import { Color } from '#shared/UI/Foundation/typing';

import { ButtonSize, Direction, Variant } from './typing';

export type ButtonStyleProps = {
  className: string;
  variant: Variant;
  size: ButtonSize;
  color?: Color;
  direction: Direction;
};

type ButtonClassnameProps = ButtonStyleProps & {
  loading?: boolean;
  disabled?: boolean;
};

const getButtonClassnames = ({
  size,
  variant,
  className,
  color,
  direction,
  loading = false,
  disabled = false,
}: ButtonClassnameProps): string => {
  const baseColor = color ?? (variant === 'solid' ? 'primary' : 'secondary');
  const nameColor = baseColor === 'primary' || baseColor === 'secondary' ? `button-${baseColor}` : baseColor;

  return clsx(
    'items-center inline-flex justify-center gap-1 font-bold transition-all',
    className,
    {
      [`!text-${nameColor}`]: variant !== 'solid',
      [`hover:!text-${nameColor}`]: variant !== 'solid' && !disabled,
      'text-button-small': size === 'small' && 'link' !== variant,
      'text-button': size !== 'small' && 'link' !== variant,
      'flex-row-reverse': direction === 'right',
      'opacity-65': loading,
      'cursor-not-allowed': disabled,
      '!opacity-30': disabled,
      'hover:opacity-65': !disabled,
      'active:opacity-100': !disabled && !loading,
    },
    'solid' === variant && {
      'rounded-button': true,
      'p-1.5': size === 'small',
      'p-2': size !== 'small',
      [`bg-${nameColor}`]: true,
      [`!text-on-${nameColor}`]: true,
      [`active:scale-95`]: !disabled && !loading,
      [`visited:!text-on-${nameColor}`]: true,
    },
    'outlined' === variant && {
      'rounded-button': true,
      'p-[10px]': size === 'small',
      'p-[14px]': size !== 'small',
      'border-2': true,
      [`!text-${nameColor}`]: true,
      [`visited:!text-${nameColor}`]: true,
      [`hover:!text-${nameColor}`]: !disabled && !loading,
      [`border-${nameColor}`]: true,
      [`active:scale-95`]: !disabled && !loading,
    },
    'text' === variant && {
      'p-1.5': size === 'small',
      'p-2': size !== 'small',
      [`active:!text-${nameColor}-dark`]: !disabled && !loading,
    },
    'link' === variant && {
      [`active:!text-${nameColor}-dark`]: !disabled && !loading,
    },
  );
};

export default getButtonClassnames;

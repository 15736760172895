import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();
  const { referrer } = document;

  useEffect(() => {
    // To prevent scroll to top on nested routing
    const referrerPathname = referrer.replace(/http(s)?:\/\/([^/]+)(:\d+)?/, '');
    if (referrerPathname.startsWith(pathname) || pathname.startsWith(referrerPathname)) {
      return;
    }
    window.scrollTo(0, 0);
  }, [pathname, referrer]);

  return null;
};

export default ScrollToTop;

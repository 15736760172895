import * as Sentry from '@sentry/react';
import React, { lazy } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import Error404 from '#shared/components/Error/404';
import Error500 from '#shared/components/Error/500';
import SharedPath from '#shared/constants/paths';
import { getEnvVar } from '#shared/envs';

import Layout from '#app/Layout';

const SecureIndex = lazy(() => import('#app/pages'));

const router = createBrowserRouter(
  [
    {
      element: <Layout />,
      children: [
        {
          path: SharedPath.NOT_FOUND,
          element: <Error404 />,
        },
        {
          path: '/*',
          element: <SecureIndex />,
        },
        {
          path: '*',
          element: <Navigate to={SharedPath.NOT_FOUND} replace />,
        },
      ],
    },
  ],
  { basename: getEnvVar('VITE_BASE_NAME') },
);

const Router: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default Sentry.withErrorBoundary(Router, {
  fallback: () => <Error500 />,
  showDialog: true,
});

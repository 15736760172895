import { AxiosRequestHeaders } from 'axios';

import { getEnvVar } from '#shared/envs';
import { getToken } from '#shared/services/token';

const isImpersonate = (): boolean => undefined !== getActAsUser();

const switchUserPath = getEnvVar('VITE_SWITCH_USER_PATH');
const actAsCustomerPath = getEnvVar('VITE_AS_CUSTOMER_PATH');

export const getSwitchUser = (): string | undefined => {
  if (switchUserPath && sessionStorage.getItem(switchUserPath)) {
    return sessionStorage.getItem(switchUserPath) ?? undefined;
  }
  return undefined;
};

export const setSwitchUser = (email: string | undefined): void => {
  if (switchUserPath) {
    if (email) {
      sessionStorage.setItem(switchUserPath, email);
    }
  }
};

export function removeSwitchUser(): void {
  if (switchUserPath) {
    sessionStorage.removeItem(switchUserPath);
  }
}

export const getActAsUser = (): string | null => {
  if (actAsCustomerPath && sessionStorage.getItem(actAsCustomerPath)) {
    return sessionStorage.getItem(actAsCustomerPath);
  }
  return null;
};

export const setActAsUser = (email: string | undefined): void => {
  if (actAsCustomerPath) {
    if (email) {
      sessionStorage.setItem(actAsCustomerPath, email);
    } else {
      sessionStorage.removeItem(actAsCustomerPath);
    }
  }
};

export function onRequestAuthentication(requestConfigHeaders: AxiosRequestHeaders): void {
  const token = getToken();
  if (token) {
    requestConfigHeaders.Authorization = `Bearer ${token}`;

    const switchToken = getSwitchUser();
    if (switchToken) {
      requestConfigHeaders['X-Switch-User'] = switchToken;
    }

    if (isImpersonate()) {
      const actAsUser = getActAsUser();
      if (null !== actAsUser) {
        requestConfigHeaders['App-As-Customer'] = actAsUser;
      }
    }
  }
}

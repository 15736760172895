import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { getEnvVar } from '#shared/envs';

export const injectScript: () => React.ReactElement | '' | false | undefined = () => {
  const DNT = !Cookies.get(getEnvVar('VITE_DO_NOT_TRACK_PATH') || 'DNT');

  if (DNT && getEnvVar('VITE_HUBSPOT_PORTAL')) {
    return (
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src={`//js.hs-scripts.com/${getEnvVar('VITE_HUBSPOT_PORTAL')}.js`}
      />
    );
  } else if ('prod' !== getEnvVar('VITE_ENV')) {
    return (
      <script type="text/javascript" id="hs-script-faker">
        {`
        (function () {
          window.HubSpotConversations = {
            widget: {
              status: function () { console.debug('HSC widget status'); return undefined },
              remove: function () { console.debug('HSC widget remove') },
              refresh: function () { console.debug('HSC widget refresh') },
              load: function () { window.alert('Hubspot conversation load') },
              open: function () { window.alert('Hubspot conversation open') },
            }
          }
        })();
        `}
      </script>
    );
  }
};

export const HubSpotConversationsWidgetRefresh: React.FC = () => {
  const { pathname } = useLocation();
  const portalId = getEnvVar('VITE_HUBSPOT_PORTAL');

  useEffect(() => {
    if (typeof window._hsq !== 'object' || !portalId) {
      return undefined;
    }
    window._hsq?.push(['trackPageView']);

    return () => {
      window.HubSpotConversations?.widget?.refresh();
      window._hsq?.push(['setPath', pathname]);
      window._hsq?.push(['trackPageView']);
    };
  }, [pathname, portalId]);

  return null;
};

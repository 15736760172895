import { FC, PropsWithChildren } from 'react';

type Props = {
  condition: boolean;
  fallback?: any;
};

const ConditionalDisplay: FC<PropsWithChildren<Props>> = ({ condition, children, fallback = null }) =>
  condition ? <>{children}</> : fallback;

export default ConditionalDisplay;

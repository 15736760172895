import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProfile } from '#shared/hooks/useProfile';

const ConnectAsToolbar: React.FC = () => {
  const { user, customer, isManager } = useProfile();
  const { t } = useTranslation(['shared']);

  if (!isManager) {
    return null;
  }

  if (!user) {
    return null;
  }

  return (
    <div className="fixed right-0 top-0 z-40 flex gap-2 bg-tertiary-blue px-1 py-0.5 text-caption text-on-tertiary-blue opacity-60">
      {user.email} | {customer ? `${t('shared:act_as')} : ${customer?.email}` : ''}
    </div>
  );
};

export default ConnectAsToolbar;

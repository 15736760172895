import * as Sentry from '@sentry/browser';
import { useState } from 'react';
import { useLocation } from 'react-router';
import useSWR, { KeyedMutator } from 'swr';

import { getItem } from '#shared/services/api';
import { getToken, setSessionToken } from '#shared/services/token';
import { Profile, ProfileAndRoles, Roles, UserProfile } from '#shared/typings/authentication';
import { PersonaType } from '#shared/typings/enums/persona';
import { getPersonaFromRole } from '#shared/utils/persona';

export const isCustomer = (customer: Profile): boolean => customer['@type'] === 'Customer';
export const isManager = (customer: Profile): boolean => customer['@type'] === 'Manager';

type ProfileHook = {
  user: UserProfile | undefined;
  customer?: UserProfile;
  actForCustomer?: UserProfile;
  roles: Roles[];
  loading: boolean;
  persona?: PersonaType;
  isGranted: (role: Roles | Roles[]) => boolean;
  isCustomer: boolean;
  isManager: boolean;
  isGuest: boolean;
  mutate: KeyedMutator<ProfileAndRoles>;
  error: any;
};

export const useProfile = (isVisitorAuthorized = false): ProfileHook => {
  const [sentryConfigured, setSentryConfigured] = useState(false);
  const [hubspotConfigured, setHubspotConfigured] = useState(false);
  const [heapConfigured, setHeapConfigured] = useState(false);

  const { search } = useLocation();
  setSessionToken(search);

  const { data, error, mutate, isLoading } = useSWR<ProfileAndRoles>(
    isVisitorAuthorized || !!getToken() ? 'profile' : null,
    getItem,
    {
      revalidateIfStale: false,
    },
  );

  const { user, actForCustomer, roles } = data || {
    user: undefined,
    actForCustomer: undefined,
    roles: [],
  };
  const customer = user && isCustomer(user) ? user : actForCustomer;

  if (!error) {
    if (user) {
      if (!sentryConfigured) {
        Sentry?.setUser({
          id: user['@id'],
          username: user.email,
          email: user.email,
        });
        setSentryConfigured(true);
      }
      if (!hubspotConfigured) {
        window?._hsq?.push(['identify', { email: user.email }]);
        setHubspotConfigured(true);
      }
      if (!heapConfigured) {
        window?.heap?.identify(user.email);
        window?.heap?.addUserProperties({ email: user.email, alreadyCustomer: true });
        setHeapConfigured(true);
      }
    } else {
      if (!heapConfigured) {
        window?.heap?.addUserProperties({ alreadyCustomer: false });
        setHeapConfigured(true);
      }
    }
  }

  const isGranted = (grantedRoles: Roles | Roles[]): boolean => {
    grantedRoles = Array.isArray(grantedRoles) ? grantedRoles : [grantedRoles];
    return roles?.some((role) => grantedRoles.includes(role)) ?? false;
  };

  const persona = getPersonaFromRole(isGranted);

  return {
    user,
    customer,
    actForCustomer,
    loading: isLoading,
    roles,
    isGuest: !user,
    isCustomer: !!user && isCustomer(user),
    isManager: !!user && isManager(user),
    persona,
    isGranted,
    error,
    mutate,
  };
};

import clsx from 'clsx';
import React from 'react';

import Icon, { IconSize } from '#shared/UI/Icon';

type Props = {
  inline?: boolean;
  className?: string;
  size?: IconSize;
};

const Loader: React.FC<Props> = ({ className, inline = false, size = 'large' }) => (
  <div
    className={clsx(!inline && 'flex h-full items-center justify-center', inline && 'inline', className)}
    data-testid="loader"
  >
    <Icon icon="loading" size={size} className={clsx('animate-spin', !inline && 'block')} />
  </div>
);

export default Loader;

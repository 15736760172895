import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Loader from '#shared/UI/Loader';
import Notification from '#shared/UI/Notification';
import DebugToolbar from '#shared/components/DebugToolbar';
import ConnectAsToolbar from '#shared/components/DebugToolbar/ConnectAsToolbar';
import ScrollToTop from '#shared/components/ScrollToTop';
import { HubSpotConversationsWidgetRefresh } from '#shared/utils/hubspot';
import { UtmToCookie } from '#shared/utils/utm';
import { WindowMessageHistorySync } from '#shared/utils/windowMessage';

const Layout: React.FC = () => {
  return (
    <>
      <Notification />
      <Suspense fallback={<Loader />}>
        <DebugToolbar />
        <ConnectAsToolbar />
        <ScrollToTop />
        <HubSpotConversationsWidgetRefresh />
        <WindowMessageHistorySync />
        <UtmToCookie />
        <Outlet />
      </Suspense>
    </>
  );
};

export default Layout;

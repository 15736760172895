import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export type WindowMessageType = 'quotes' | 'navigation' | 'claims';

export type WindowMessage<T extends WindowMessageType> = {
  type: T;
  payload: WindowMessagePayload<T>;
};

type QuoteMessagePayload = {
  type: 'created' | 'updated' | 'signed';
  id: string;
};

type ClaimMessagePayload = {
  type: 'created';
  id: string;
};

export type WindowMessagePayload<WindowMessageType> = WindowMessageType extends 'quotes'
  ? QuoteMessagePayload
  : WindowMessageType extends 'claims'
    ? NavigationMessagePayload
    : ClaimMessagePayload;

type NavigationMessagePayload = {
  pathname: string;
};

export const sendWindowMessage = (message: WindowMessage<any>): void => {
  window?.opener?.postMessage(JSON.stringify(message), '*');
};

export const WindowMessageHistorySync: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    sendWindowMessage({
      type: 'navigation',
      payload: {
        pathname,
      },
    });
  }, [pathname]);

  return null;
};

import { addMethod, string } from 'yup';

const SIRET_LENGTH = 14;

addMethod(string, 'siret', function (message) {
  return this.test('siret-test', message, (value: any) => {
    const valueParse = value.replace(/\s/g, '');
    if (!/^[0-9]{14}$/.test(valueParse)) {
      return false;
    }

    if (valueParse.length !== SIRET_LENGTH) {
      return false;
    }

    let sum = 0;
    for (let i = 0; i < SIRET_LENGTH; ++i) {
      let tmp = (2 - ((SIRET_LENGTH - i) % 2)) * valueParse[i];
      if (tmp >= 10) {
        tmp -= 9;
      }
      sum += tmp;
    }

    return sum > 0 && sum % 10 === 0;
  });
});

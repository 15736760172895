import companyProperties from './companyProperties.fr.json';
import partner from './partner.fr.json';
import productTypes from './productTypes.fr.json';
import shared from './shared.fr.json';
import stripe from './stripe.fr.json';
import violations from './violations.fr.json';

const fr = {
  companyProperties: companyProperties,
  violations: violations,
  productTypes: productTypes,
  shared: shared,
  stripe: stripe,
  partner: partner,
};

export { fr };

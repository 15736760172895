import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as accordion from '#shared/UI/Accordion/translations';
import * as uploadInput from '#shared/UI/Input/UploadInput/translations';
import * as contact from '#shared/components/Contact/translations';
import * as errors from '#shared/components/Error/translations';
import * as sharedTranslationsFr from '#shared/translations';

import contractsFr from '#app/features/Contract/translations/fr.json';
import * as customerArea from '#app/features/CustomerArea/translations';
import documentsFr from '#app/features/Document/translations/fr.json';
import eventsFr from '#app/i18n/events.fr.json';
import sharedFr from '#app/i18n/fr.json';
import questionFr from '#app/i18n/question.fr.json';

export const resources = {
  fr: {
    question: questionFr,
    shared: { ...sharedFr, ...sharedTranslationsFr.fr.productTypes, ...sharedTranslationsFr.fr.shared },
    events: eventsFr,
    contracts: contractsFr,
    documents: documentsFr,
    customerArea: customerArea.fr,
    errors: errors.fr,
    ui: {
      contact: contact.fr,
    },
    accordion: accordion.fr,
    uploadInput: uploadInput.fr,
    violations: sharedTranslationsFr.fr.violations,
    companyProperties: sharedTranslationsFr.fr.companyProperties,
    stripe: sharedTranslationsFr.fr.stripe,
  },
};

i18n.use(initReactI18next).init({
  resources,
  debug: false,
  lng: 'fr',
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['strong', 'b', 'br', 'i', 'ul', 'li', 'u'],
  },
  saveMissing: true,
  missingKeyHandler: (_lng, ns, key) => {
    console.error(`missing translation in namespace: '${ns}' with key '${key}'`);
  },
});

export default i18n;

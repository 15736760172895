import React from 'react';
import { ToastContainer } from 'react-toastify';

import Icon from '#shared/UI/Icon';
import Typography from '#shared/UI/Typography';

type Props = {
  title?: React.ReactNode;
  message: React.ReactNode;
};

export const NotificationContent: React.FC<Props> = ({ title, message }) => (
  <>
    {title && <Typography variant="body-bold">{title}</Typography>}
    <Typography variant="text">{message}</Typography>
  </>
);

const Notification: React.FC = () => (
  <ToastContainer
    position="bottom-left"
    draggable={false}
    theme="colored"
    toastClassName="rounded p-2 gap-2"
    bodyClassName="m-0 p-0 gap-2 items-start"
    progressClassName="h-0.5"
    closeButton={() => <Icon icon="close" size="medium" />}
  />
);

export default Notification;

const paths = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  NOT_FOUND: '/404',
  COACH: '/coach',
  ENDORSEMENT: '/endorsement',
  HEALTHCARE: '/healthcare',
  MATCHING: '/matching',
  REVENUE_LOSS: '/revenue-loss',
  SUBSCRIPTION: '/subscription',
  OAUTH_CALLBACK: '/oauth/callback',
};

export type PathKey = keyof typeof paths;

export default paths;

import Cookies from 'js-cookie';
import React from 'react';

import { getEnvVar } from '#shared/envs';

export const injectGTM: () => React.ReactNode = () =>
  !Cookies.get(getEnvVar('VITE_DO_NOT_TRACK_PATH') || 'DNT') && getEnvVar('VITE_GTM_APP') ? (
    <script>
      {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${getEnvVar('VITE_GTM_APP')}');
      `}
    </script>
  ) : null;

export const injectGTMNoScript: () => React.ReactNode = () =>
  !Cookies.get(getEnvVar('VITE_DO_NOT_TRACK_PATH') || 'DNT') && getEnvVar('VITE_GTM_APP') ? (
    <noscript>
      <iframe
        title="gtm"
        src={`https://www.googletagmanager.com/ns.html?id=${getEnvVar('VITE_GTM_APP')}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  ) : null;

import React from 'react';

import { getEnvVar, getPartner } from '#shared/envs';

export const injectLink: () => React.ReactNode = () => {
  const partner = getPartner();

  return (
    <>
      <link rel="icon" type="image/png" href="/brands/stello/favicon.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/brands/stello/favicon-apple.png" />
      <link rel="stylesheet" href="/brands/stello/theme.css?v=202402" />
      {!!partner && <link rel="stylesheet" href={partner.stylesheet} />}
    </>
  );
};

export const injectMeta: () => React.ReactNode = () => (
  <>
    <meta itemProp="name" content={getEnvVar('VITE_TITLE_APP')} />
    <meta itemProp="description" content={getEnvVar('VITE_DESCRIPTION')} />
    <meta name="description" content={getEnvVar('VITE_DESCRIPTION')} />
    <meta name="robots" content="none" />
  </>
);

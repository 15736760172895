import React, { AnchorHTMLAttributes, ComponentProps, LinkHTMLAttributes } from 'react';

import Button from '#shared/UI/Button';
import getButtonClassnames from '#shared/UI/Button/classnames';
import Icon from '#shared/UI/Icon';
import Typography, { Variant as TypographyVariant } from '#shared/UI/Typography';
import ConditionalDisplay from '#shared/components/ConditionalDisplay';

type ExternalLinkButtonProps = Omit<ComponentProps<typeof Button>, 'ref'> & {
  target?: string;
  classNameIcon?: string;
};

const ExternalLinkButton: React.FC<
  React.PropsWithChildren<
    ExternalLinkButtonProps & LinkHTMLAttributes<HTMLLinkElement> & AnchorHTMLAttributes<HTMLAnchorElement>
  >
> = ({
  rel = 'noopener noreferrer',
  className = '',
  size = 'large',
  color,
  variant = 'solid',
  target = '_blank',
  icon,
  direction = 'left',
  children,
  iconSize,
  classNameIcon,
  ...htmlProps
}) => {
  return (
    <a
      className={getButtonClassnames({
        direction,
        variant,
        size,
        color,
        className,
      })}
      rel={rel}
      target={target}
      {...htmlProps}
    >
      {icon && (
        <Icon
          icon={icon}
          size={iconSize ?? (size === 'small' ? 'small' : 'medium')}
          className={classNameIcon || 'leading-4'}
        />
      )}
      <ConditionalDisplay condition={children !== undefined}>
        <Typography variant={`${variant === 'link' ? 'link' : 'button'}-${size}` as TypographyVariant}>
          {children}
        </Typography>
      </ConditionalDisplay>
    </a>
  );
};

export default ExternalLinkButton;

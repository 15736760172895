import clsx from 'clsx';
import React, { HTMLAttributes, PropsWithChildren } from 'react';

import styles from '#shared/UI/Typography/Typography.module.scss';

export type Variant =
  | 'title1'
  | 'title2'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle-bold'
  | 'subtitle'
  | 'body'
  | 'body-bold'
  | 'text'
  | 'button-large'
  | 'button-small'
  | 'link-large'
  | 'link-small'
  | 'caption'
  | 'caption-bold'
  | 'overline';

type Component = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p' | 'div' | 'td' | 'th' | 'ul';

type Props = HTMLAttributes<HTMLElement> & {
  variant: Variant;
  component?: Component;
  className?: string;
};

const defaultVariantMapping: Record<Variant, Component> = {
  title1: 'h1',
  title2: 'h1',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  'subtitle-bold': 'div',
  subtitle: 'div',
  body: 'div',
  'body-bold': 'div',
  text: 'div',
  'button-large': 'span',
  'button-small': 'span',
  'link-large': 'span',
  'link-small': 'span',
  caption: 'div',
  'caption-bold': 'div',
  overline: 'div',
};

const defaultVariantStyle: Record<Variant, string> = {
  title1: 'md:text-title1 text-title2',
  title2: 'md:text-title2 text-h1 ',
  h1: 'md:text-h1 text-h2',
  h2: 'md:text-h2 text-h3',
  h3: 'text-h3',
  h4: 'text-h4',
  h5: 'text-h5',
  h6: 'text-h6',
  'subtitle-bold': 'text-subtitle-bold',
  subtitle: 'text-subtitle',
  body: 'text-body',
  'body-bold': 'text-body-bold',
  text: 'text-text',
  'button-large': 'text-button',
  'button-small': 'text-button-small',
  'link-large': 'text-link-large',
  'link-small': 'text-link-small',
  caption: 'text-caption',
  'caption-bold': 'text-caption-bold',
  overline: 'text-overline',
};

const Typography: React.FC<PropsWithChildren<Props>> = ({ children, variant, component, className, ...props }) => {
  const Module = component || defaultVariantMapping[variant];

  return (
    <Module className={clsx(defaultVariantStyle[variant], styles.typography, className)} {...props}>
      {children}
    </Module>
  );
};

export default Typography;

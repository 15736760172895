import clsx from 'clsx';
import React, { ComponentProps, MouseEventHandler } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import Button from '#shared/UI/Button';
import getButtonClassnames from '#shared/UI/Button/classnames';
import Icon from '#shared/UI/Icon';
import Typography, { Variant as TypographyVariant } from '#shared/UI/Typography';
import ConditionalDisplay from '#shared/components/ConditionalDisplay';

export const LinkButton: React.FC<
  React.PropsWithChildren<Omit<LinkProps, 'component' | 'ref'> & Omit<ComponentProps<typeof Button>, 'ref'>>
> = ({
  id,
  children,
  className = '',
  size = 'large',
  variant = 'solid',
  color,
  direction = 'left',
  icon,
  iconSize,
  classNameIcon,
  disabled,
  loading,
  ...htmlProps
}) => {
  const disabledClickHandler: MouseEventHandler = (event) => event.preventDefault();

  return (
    <Link
      id={id}
      className={getButtonClassnames({
        className,
        size,
        variant,
        color,
        direction,
        loading,
        disabled,
      })}
      onClick={disabled ? disabledClickHandler : htmlProps.onClick}
      {...htmlProps}
    >
      {icon && (
        <Icon
          icon={icon}
          size={iconSize ?? (size === 'small' ? 'small' : 'medium')}
          className={clsx(classNameIcon, 'leading-unset')}
        />
      )}
      <ConditionalDisplay condition={children !== undefined}>
        <Typography variant={`${variant === 'link' ? 'link' : 'button'}-${size}` as TypographyVariant}>
          {children}
        </Typography>
      </ConditionalDisplay>
    </Link>
  );
};

export default LinkButton;

import clsx from 'clsx';
import React, { HTMLAttributes } from 'react';

import { Size } from '#shared/UI/Foundation/typing';

export type IconSize = Size | 'auto';

export type IconName =
  | 'cart'
  | 'check'
  | 'lightning'
  | 'lightning-solid'
  | 'bell'
  | 'question-mark'
  | 'information'
  | 'exclamation'
  | 'archive'
  | 'clock'
  | 'key'
  | 'check-simple'
  | 'dashboard'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'chevron-down'
  | 'more'
  | 'adjustment'
  | 'search'
  | 'loading'
  | 'turn-off'
  | 'close'
  | 'edit-note'
  | 'document'
  | 'euro'
  | 'paper-clip'
  | 'download'
  | 'users'
  | 'user'
  | 'plus'
  | 'closed'
  | 'danger'
  | 'info'
  | 'pending'
  | 'success'
  | 'warning'
  | 'office-building'
  | 'folder-download'
  | 'eye-off'
  | 'eye'
  | 'hubspot'
  | 'logout'
  | 'minus'
  | 'sorting'
  | 'sorting-up'
  | 'sorting-down'
  | 'empty'
  | 'calendar'
  | 'close-circle'
  | 'receipt'
  | 'edit'
  | 'location'
  | 'contact-person'
  | 'chevron-double-left'
  | 'chevron-double-right'
  | 'external-link'
  | 'settings'
  | 'chat-bubble'
  | 'clipboard'
  | 'trash'
  | 'shield'
  | 'copy'
  | 'mail'
  | 'lightbulb'
  | 'plus-circle'
  | 'stello-star'
  | 'folder'
  | 'history'
  | 'upload'
  | 'image'
  | 'pause'
  | 'phone'
  | 'document-chart'
  | 'burger'
  | 'flame'
  | 'flame-solid'
  | 'backpack'
  | 'switch-user'
  | 'filter'
  | 'command'
  | 'no-filter'
  | 'refresh'
  | 'qonto'
  | 'plane'
  | 'chain'
  | 'lock';

type Props = HTMLAttributes<HTMLElement> & {
  className?: string;
  size?: IconSize;
  icon: IconName;
};

const Icon: React.FC<Props> = ({ className, size = 'small', icon, ...props }) => (
  <i
    className={clsx(
      `icon-${icon}`,
      {
        'text-icon-small': 'small' === size,
        'text-icon-medium': 'medium' === size,
        'text-icon-large': 'large' === size,
      },
      className,
    )}
    {...props}
  />
);

export default Icon;

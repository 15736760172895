import accountFr from '#app/features/CustomerArea/translations/account.fr.json';
import bannerContactFr from '#app/features/CustomerArea/translations/bannerContact.fr.json';
import claimFr from '#app/features/CustomerArea/translations/claim.fr.json';
import companyFr from '#app/features/CustomerArea/translations/company.fr.json';
import contactFr from '#app/features/CustomerArea/translations/contact.fr.json';
import contractFr from '#app/features/CustomerArea/translations/contract.fr.json';
import dashboardFr from '#app/features/CustomerArea/translations/dashboard.fr.json';
import documentsFr from '#app/features/CustomerArea/translations/documents.fr.json';
import paymentFr from '#app/features/CustomerArea/translations/payment.fr.json';

const fr = {
  dashboard: dashboardFr,
  payment: paymentFr,
  documents: documentsFr,
  company: companyFr,
  bannerContact: bannerContactFr,
  contract: contractFr,
  claim: claimFr,
  account: accountFr,
  contact: contactFr,
};

export { fr };

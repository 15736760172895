import { Roles } from '#shared/typings/authentication';
import { PersonaType } from '#shared/typings/enums/persona';

const PersonaRecord: Partial<Record<Roles, PersonaType>> = {
  ROLE_ADMINISTRATOR: PersonaType.ADMIN,
  ROLE_MANAGER: PersonaType.MANAGER,
  ROLE_VISITOR: PersonaType.VISITOR,
  ROLE_CUSTOMER: PersonaType.CUSTOMER,
};

export const getPersonaFromRole = (isGranted: (grantedRoles: Roles | Roles[]) => boolean): PersonaType | undefined => {
  const roles: Roles[] = ['ROLE_VISITOR', 'ROLE_CUSTOMER', 'ROLE_MANAGER', 'ROLE_ADMINISTRATOR'];
  for (const role of roles) {
    if (isGranted(role) && PersonaRecord[role]) {
      return PersonaRecord[role];
    }
  }
  return undefined;
};

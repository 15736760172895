import React from 'react';
import { Trans } from 'react-i18next';

import LinkButton from '#shared/UI/LinkButton';
import Typography from '#shared/UI/Typography';
import Image from '#shared/assets/img/crossroads.png';
import { ReactComponent as Watermark } from '#shared/assets/img/watermark.svg';

const Error404: React.FC = () => (
  <div className="flex h-full items-center justify-center bg-primary-dark p-6">
    <Watermark className="absolute top-0 h-screen w-full" />
    <div className="relative z-1 mx-auto flex max-w-lg flex-col gap-3 max-md:items-center max-md:text-center md:items-start md:pr-[45vw] xl:pr-[630px]">
      <img src="/brands/stello/logo-white.svg" alt="" className="h-4" />
      <img
        src={Image}
        alt=""
        className="w-[60vw] max-w-[530px] sm:w-[40vw] md:absolute md:right-0 md:top-1/2 md:-translate-y-1/2"
      />
      <Typography variant="h1" className="!text-white md:mb-1 md:mt-5">
        <Trans i18nKey="errors:404.title" />
      </Typography>
      <LinkButton id="go-to-dashboard" to="/">
        <Trans i18nKey="errors:404.button" />
      </LinkButton>
    </div>
  </div>
);

export default Error404;

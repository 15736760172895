import axios, { AxiosError, AxiosResponse } from 'axios';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Icon from '#shared/UI/Icon';
import instance from '#shared/services/api';

import { getEnv } from '../../envs';

const captureScreenshot = async (): Promise<void> => {
  if (!navigator || !navigator?.mediaDevices?.getDisplayMedia) {
    return;
  }

  try {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      // @ts-ignore
      preferCurrentTab: true,
      audio: false,
    });
    const video = document.createElement('video');
    video.autoplay = true;

    video.addEventListener('loadedmetadata', () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
      stream.getVideoTracks().forEach((track) => track.stop());
      const image = canvas.toDataURL();
      const downloadLink = document.createElement('a');
      downloadLink.href = image;
      downloadLink.target = '_self';
      downloadLink.download = 'screenshot.png';
      downloadLink.click();
    });
    video.srcObject = stream;
  } catch (error) {
    console.error(error);
  }
};

const DebugToolbar: React.FC = () => {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState<boolean>(true);

  const [totalRequestCount, setTotalRequestCount] = useState(0);
  const [errorResponseCount, setErrorResponseCount] = useState(0);
  const [cancelledRequestCount, setCancelledRequestCount] = useState(0);
  const [requests, setRequests] = useState<Array<AxiosError | AxiosResponse>>([]);

  const [totalRequestForPageCount, setTotalRequestForPageCount] = useState(0);
  const [onGoingRequestForPageCount, setOnGoingRequestForPageCount] = useState(0);
  const [errorResponseForPageCount, setErrorResponseForPageCount] = useState(0);
  const [cancelledRequestForPageCount, setCancelledRequestForPageCount] = useState(0);

  const forced = !!Cookies.get('debug_mode');

  const env = getEnv();

  useEffect(() => {
    if ('prod' === env && !forced) {
      return;
    }
    const reqInterceptor = instance.interceptors.request.use((requestConfig) => {
      setOnGoingRequestForPageCount((prevState) => prevState + 1);
      setTotalRequestCount((prevState) => prevState + 1);
      setTotalRequestForPageCount((prevState) => prevState + 1);
      return requestConfig;
    });
    const resInterceptor = instance.interceptors.response.use(
      (response) => {
        setOnGoingRequestForPageCount((prevState) => (prevState >= 1 ? prevState - 1 : 0));
        setRequests((prevState) => [...prevState, response]);
        return response;
      },
      (error) => {
        setOnGoingRequestForPageCount((prevState) => (prevState >= 1 ? prevState - 1 : 0));
        if (axios.isCancel(error)) {
          setCancelledRequestCount((prevState) => prevState + 1);
          setCancelledRequestForPageCount((prevState) => prevState + 1);
        } else {
          setRequests((prevState) => [...prevState, error]);
          setErrorResponseCount((prevState) => prevState + 1);
          setErrorResponseForPageCount((prevState) => prevState + 1);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      instance.interceptors.request.eject(reqInterceptor);
      instance.interceptors.response.eject(resInterceptor);
    };
  }, []);

  useEffect(() => {
    setTotalRequestForPageCount(0);
    setOnGoingRequestForPageCount(0);
    setErrorResponseForPageCount(0);
    setCancelledRequestForPageCount(0);
  }, [pathname]);

  if (('prod' === env && !forced) || !visible) {
    return null;
  }

  return (
    <div
      className={clsx(
        'fixed top-0 z-40 flex w-full items-center gap-2 px-1 text-caption opacity-80',
        'dev' === env && 'bg-purple-light text-on-purple-light',
        'feature' === env && 'bg-deep-yellow-light text-on-deep-yellow-light',
        'preproduction' === env && 'bg-deep-orange-light text-on-deep-orange-light',
        'prod' === env && 'bg-tertiary-red text-on-tertiary-red',
      )}
    >
      <button type="button" onClick={() => setVisible(false)}>
        <Icon icon="close" />
      </button>
      <div>
        Env: <b>{env}</b>
      </div>
      <div>
        Version: <b>{window.APP_VERSION}</b>
      </div>
      <button type="button" onClick={() => captureScreenshot()}>
        <Icon icon="image" />
      </button>
      <div className="group cursor-pointer py-0.5">
        Session: Total {totalRequestCount} | Cancelled {cancelledRequestCount} | Error {errorResponseCount}
        <ul
          className={clsx(
            'custom-scrollbar absolute bottom-0 hidden max-h-[80vh] translate-y-full list-none flex-col-reverse overflow-auto p-0 group-hover:flex',
            'dev' === env && 'bg-purple-light',
            'feature' === env && 'bg-deep-yellow-light',
            'preproduction' === env && 'bg-deep-orange-light',
            'prod' === env && 'bg-tertiary-red',
          )}
        >
          {requests.map((request: AxiosResponse | AxiosError, index) => {
            const req: XMLHttpRequest = request.request;
            if (!req?.responseURL) {
              return null;
            }
            const url = new URL(req.responseURL);

            return (
              <li
                key={index}
                className="cursor-pointer p-0.5 hover:underline hover:underline-offset-1"
                onClick={() => console.debug(request)}
              >
                #{index} <b>{request.config?.method?.toUpperCase()}</b> {req.status} {url.pathname}
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        Page: Total {totalRequestForPageCount} | Ongoing {onGoingRequestForPageCount} | Cancelled{' '}
        {errorResponseForPageCount} | Error {cancelledRequestForPageCount}
      </div>
    </div>
  );
};

export default DebugToolbar;

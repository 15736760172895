import Cookies from 'js-cookie';
import React, { useEffect } from 'react';

import { getEnvVar } from '#shared/envs';
import { useQuery } from '#shared/hooks/useQuery';

type UtmQueryParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

export const UtmToCookie: React.FC = () => {
  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = useQuery<UtmQueryParams>();
  useEffect(() => {
    const utm: UtmQueryParams = {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    };

    if ((Object.keys(utm) as (keyof UtmQueryParams)[]).filter((key) => utm[key] !== undefined).length) {
      // If a value is undefined, JSON.stringify will not return it
      Cookies.set('tracking_utm', JSON.stringify(utm), {
        expires: 30,
        domain: getEnvVar('VITE_COOKIE_DOMAIN'),
        path: '/',
      });
    }
  }, []);
  return null;
};

import { AxiosRequestConfig } from 'axios';
import { omitBy } from 'lodash';
import qs from 'qs';

import { getEnvVar } from '#shared/envs';
import { getSwitchUser } from '#shared/services/switchUser';
import { getToken } from '#shared/services/token';

export type APIServiceOptions = {
  requestOptions: AxiosRequestConfig;
};

export const iriToId = (iri: string): string => {
  const matches = iri.match(/.+\/(.+)/);
  return matches?.[1] ?? iri;
};

export const sanitizeUrl = (url: string): string => url.replace(/([^:]\/)\/+/g, '$1');

export const normalizeIri = (iri: string): string => sanitizeUrl(iri.replace(getEnvVar('VITE_API_PATH') || '', ''));
export const normalizeApiUrl = (path = '', ignoreApiPath = false): string =>
  sanitizeUrl(
    path.startsWith(getEnvVar('VITE_API_PATH') as string) || ignoreApiPath
      ? `${getEnvVar('VITE_API_ENDPOINT')}/${path}`
      : `${getEnvVar('VITE_API_ENDPOINT')}${getEnvVar('VITE_API_PATH')}/${path}`,
  );

export const normalizeStaticUrl = (path: string): string => sanitizeUrl(`${getEnvVar('VITE_STATIC_ENDPOINT')}/${path}`);
export const buildQueryParams = (params: object): string =>
  qs.stringify(omitBy(params, (value) => undefined === value || null === value || '' === value));
export const buildUrl = (url: string, params: object): string => `${url}?${buildQueryParams(params)}`;

type buildCrossAppUrlType = (
  app: 'VITE_APP_HOST' | 'VITE_AUTH_HOST' | 'VITE_EXTRANET_HOST' | 'VITE_QUOTE_HOST',
  url: string,
  params?: Record<string, string>,
) => string;

export const buildCrossAppUrl: buildCrossAppUrlType = (app, url, params = {}) => {
  const appAsCustomer = sessionStorage.getItem(getEnvVar('VITE_AS_CUSTOMER_PATH') as string);
  if (appAsCustomer) {
    params.appAsCustomer = appAsCustomer;
  }

  const switchUserToken = sessionStorage.getItem(getEnvVar('VITE_SWITCH_USER_PATH') as string);
  if (switchUserToken) {
    params.switchUserToken = switchUserToken;
  }

  const jwtToken = sessionStorage.getItem(getEnvVar('VITE_JWT_PATH') as string);
  if (jwtToken) {
    params.token = jwtToken;
  }

  return getEnvVar(app) + (!Object.keys(params).length ? url : buildUrl(url, params));
};

export const appendSecurityToUrl = (href: string): URL => {
  const url = new URL(href, document.location.origin);
  url.searchParams.append('Application', 'stello');
  const token = getToken();
  if (token) {
    url.searchParams.append('bearer', token);
  }

  const switchUserToken = getSwitchUser();
  if (undefined !== switchUserToken) {
    url.searchParams.append('X-Switch-User', switchUserToken);
  }

  return url;
};
